<template>
  <div id="cast-first-time-register">
    <AppContainer :useBackground="true">
      <div class="cast-first-time-register">
        <div>
          <img
            class="logo-prego"
            alt="logo-prego"
            src="@/assets/image/home_top/prego.svg"
          />
        </div>
        <div>
          <img
            class="logo-welcome"
            alt="logo-welcome"
            src="@/assets/image/home_top/welcome.svg"
          />
        </div>
        <div class="group">
          <button class="btn-register" @click="nextPageRegister()">
            <img
              src="@/assets/image/home_top/button.svg"
              class="button-login"
              alt=""
            />
            <span>新規登録</span>
          </button>
          <img
            @click="nextPageLogin()"
            src="@/assets/image/home_top/login.svg"
            class="button-login"
            alt=""
          />
        </div>
      </div>
    </AppContainer>
    <ModalConfirmEmail ref="confirm-email">
      <div class="f-w3">
        <img
          src="@/assets/image/background/icon_email.svg"
          class="image-email"
          alt=""
        />
        <p class="text-confirm-email">
          ご登録用のメールアドレスにメールを送信しま
        </p>
        <p class="text-confirm-email">
          したので、そちらからご登録お願いします
        </p>
      </div>
    </ModalConfirmEmail>
    <ModalLayout ref="modal-delete">
      <img
        src="@/assets/image/home_top/delete_user.svg"
        alt=""
        class="image-delete-user"
      />
      <p class="modal-logout-title f-w3">
        またのご利用をお待ちしております
      </p>
    </ModalLayout>
  </div>
</template>

<script>
import router from "@/router";
import Cookies from "js-cookie";
import ModalConfirmEmail from "@/views/Common/ModalConfirmEmail.vue";
export default {
  name: "FirstTimeRegister",
  components: {
    ModalConfirmEmail
  },
  data() {
    return {
      isShowModalConfirmEmail: false
    };
  },
  metaInfo() {
    return {
      title: "初めて登録する",
      titleTemplate: "%s | PreGo"
    };
  },
  mounted() {
    if (!this.$route.query.code) {
      if (Cookies.get("invitation_code")) {
        Cookies.remove("invitation_code");
      }
    }
    if (this.$route.query.register == "success") {
      this.$refs["confirm-email"].openModal();
    }
    if (this.$route.params.delete == "success") {
      this.$refs["modal-delete"].openModal();
    }
  },
  methods: {
    nextPageRegister() {
      this.$root.$refs.loading.start();
      if (this.$route.query.code) {
        router.push({
          name: "Register",
          query: { code: this.$route.query.code }
        });
      } else {
        router.push({ name: "Register" });
      }
      this.$root.$refs.loading.finish();
    },
    nextPageLogin() {
      this.$root.$refs.loading.start();
      router.push({ name: "LoginEmail" });
      this.$root.$refs.loading.finish();
    }
  },
  beforeRouteEnter(to, from, next) {
    next(() => {
      if (from.name === "ShareApp") {
        localStorage.clear();
        if (Cookies.get("prego_token")) {
          Cookies.remove("prego_token");
        }
      }
      if (from.name === "Verify") {
        localStorage.clear();
        if (Cookies.get("prego_token")) {
          Cookies.remove("prego_token");
        }
      }
    });
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/castFirstTimeRegister.scss";
#cast-first-time-register {
  /deep/ .app-body._header .app-container__content {
    background-color: #f5f5f5;
  }
  /deep/ .app-container {
    &__content {
      height: 100vh;
    }
  }

  /deep/.app-modal.default .app-modal__dialog {
    width: 375px;
  }

  .image-delete-user {
    margin-top: 33px;
  }
  .modal-logout-title {
    margin-top: 25px;
    font-size: 16px;
    margin-bottom: 33px;
  }
}
</style>
